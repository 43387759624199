import { graphql } from "gatsby"
import React from "react"
import BackgroundImage2 from "../components/BackgroundImage"
import BlockContentPrimary from "../components/blockContentPrimary"
import { CustomSection } from "../components/CustomElements"
import DisplayPosts from "../components/DisplayPosts"
import Files from "../components/Files"
import Hero from "../components/Hero"
import Layout from "../components/layout"
import SectionTitle from "../components/SectionTitle"
import SEO from "../components/seo"

const COVID19 = ({ data }) => {
  const { blogPosts, sanityCovid19 } = data
  const {
    _rawMainContent: rawMainContent,
    files,
    title,
    heroImage,
  } = sanityCovid19
  const posts = blogPosts.edges

  let hero = null
  if (heroImage == null || heroImage.asset == null) {
    hero = null
  } else {
    hero = heroImage.asset
  }

  return (
    <Layout>
      <SEO title="COVID-19." />
      <Hero englishTitle={title} heroImage={hero} />
      <div className="flex flex-col items-center mt-10 mx-3 ">
        <CustomSection className="w-full md:w-3/4">
          <BlockContentPrimary blockData={rawMainContent} />
        </CustomSection>
        {files.length >= 1 && <Files files={files} />}
      </div>
      <SectionTitle>COVID-19 Updates</SectionTitle>
      <DisplayPosts posts={posts} />
    </Layout>
  )
}

export const query = graphql`
  query COVID19Query {
    sanityCovid19 {
      _rawMainContent(resolveReferences: { maxDepth: 10 })
      title
      heroImage {
        asset {
          fluid(maxWidth: 1800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      files {
        asset {
          title
          url
          originalFilename
          id
        }
      }
    }

    blogPosts: allSanityBlogPost(
      filter: { covid19: { eq: true } }
      sort: { fields: _createdAt, order: DESC }
    ) {
      edges {
        node {
          ...DisplayPostsFragment
        }
      }
    }
  }
`

export default COVID19
