import React from "react"
import Img from "gatsby-image"

import ButtonTemplate from "../buttons/ButtonTemplate"

export default ({
  fluid = null,
  text = "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web de- signs. The passage is attributed to an unknown.....",
  title = "Post Title",
  handle = "#",
  date = null,
  blogIndex = null,
}) => {
  const colors = ["blue", "red", "yellow"]

  const buttonColors = [
    { light: "rgb(107, 138, 167)", dark: "rgb(75, 103, 129)" },
    { light: "rgb(197,113,109)", dark: "rgb(186,63,76)" },
    { light: "rgb(225,184,77)", dark: "rgb(195,153,61)" },
  ]
  return (
    <div
      className={`grid gap-x-5 ${
        fluid ? "lg:grid-flow-col lg:grid-cols-2" : ""
      }  bg-gray-light lg:px-8  `}
    >
      {/* Only render an image if one is provided in CMS */}
      {fluid && <Img className="" fluid={fluid} />}
      <div>
        <div
          className={`flex flex-col justify-between ${
            fluid ? "" : ""
          } mx-4 lg:mx-0  mt-8`}
        >
          <div>
            <h3 className="font-heading text-4xl">{title}</h3>
            {date ? <div className="mb-2">Published: {date}</div> : null}
            <p className="font-heading text-lg lg:mr-32">{text}</p>
          </div>

          <div className=" grid justify-items-end ">
            <ButtonTemplate
              to={`/news/${handle}`}
              text="Continue Reading"
              className=" mb-12 text-white"
              triangleFill={buttonColors[blogIndex % 3].dark}
              bannerBg={buttonColors[blogIndex % 3].light}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
